import React from "react";
import Main from './main.jsx';

const App = () => (
  <div>  
    <Main />
  </div>
)

export default App







